
import GenelIstatistikGrafikInfo from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikGrafikInfo.vue";
import GenelIstatistikPerformansGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikPerformansGrafik.vue";
import {Component, Vue} from "vue-property-decorator";
import UstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/UstBasliklar.vue";
import {GenelRaporlamaEntity} from "@/entity/Raporlama/GenelRaporlamaEntity";
import GenelIstatistikDerdest from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikDerdest.vue";
import GenelIstatistikHitam from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikHitam.vue";

@Component({
  components: {
    GenelIstatistikHitam,
    GenelIstatistikDerdest, UstBasliklar, GenelIstatistikPerformansGrafik, GenelIstatistikGrafikInfo
  }
})
export default class GenelRaporlamaView extends Vue {
  items: Array<any> = [];
  items2: Array<any> = [];
  allData: any = []
  allDataDerdest: any = []
  allDataHitam: any = []

  loading: boolean = false;
  selectedItemTahsilatOrani = null
  tahsilatOraniTitle = ["Genel Toplam", "Asıl Alacak", "Takip Toplamı"]
  grafikData !: string | number
  grafikDataDerdest !: string | number
  grafikDataHitam !: string | number

  mounted() {
    this.selectItemOran('Genel Toplam')

  }

  async fetchData(typeParam: string = "genel_toplam", genelToplamCard: string = "Genel Toplam") {
    try {

      const response: any = await this.$http.get('/api/v1/tahsilat-takip-dosya-getir/', {
        params: {
          Type: typeParam
        }
      });


      this.allData = [(response.acilmamis_dosya_sayisi / response.toplam_dosya_sayisi) * 100]
      this.allDataDerdest = [(response.derdest_dosya_sayisi / response.toplam_dosya_sayisi) * 100]
      this.allDataHitam = [(response.hitam_dosya_sayisi / response.toplam_dosya_sayisi) * 100]

      this.grafikData = Number((response.acilmamis_dosya_sayisi / response.toplam_dosya_sayisi) * 100).toFixed(0)
      this.grafikDataDerdest = Number((response.derdest_dosya_sayisi / response.toplam_dosya_sayisi) * 100).toFixed(0)
      this.grafikDataHitam = Number((response.hitam_dosya_sayisi / response.toplam_dosya_sayisi) * 100).toFixed(0)


      this.items = this.mapItems([
        {isim: 'Toplam Dosya Sayısı', data: response.toplam_dosya_sayisi || '0'},
        {isim: 'Açılmamış Dosya Sayısı', data: response.acilmamis_dosya_sayisi || '0'},
        {isim: 'Derdest Dosya Sayısı', data: response.derdest_dosya_sayisi || '0'},
        {isim: 'Hitam Dosya Sayısı', data: response.hitam_dosya_sayisi || '0'},
      ]);

      this.items2 = this.mapItems([
        {
          id: 0,
          isim: genelToplamCard,
          data:  this.$helper.tutarStrIstatistik(response.takip_tutari) || '0 ₺',
          icon: 'mdi-file-document-plus'
        },
        {
          id: 1,
          isim: `Açılmamış ${genelToplamCard}`,
          data: this.$helper.tutarStrIstatistik(response.acilmamis_takip_tutar_toplami) || '0 ₺',
          icon: 'mdi-file-document-plus'
        },
        {
          id: 2,
          isim: `Derdest ${genelToplamCard}`,
          data: this.$helper.tutarStrIstatistik(response.derdest_takip_tutar_toplami) || '0 ₺',
          icon: 'mdi-file-document-plus'
        },
        {
          id: 3,
          isim: `Hitam ${genelToplamCard}`,
          data: this.$helper.tutarStrIstatistik(response.hitam_takip_tutar_toplami)  || '0 ₺',
          icon: 'mdi-file-document-plus'
        },
        {
          id: 4,
          isim: 'Tahsilat Toplamı',
          data: this.$helper.tutarStrIstatistik(response.genel_tahsilat_toplami) || '0 ₺',
          icon: 'mdi-cash-multiple'
        },
        {
          id: 5,
          isim: 'Açılmamış Tahsilat Toplamı',
          data: this.$helper.tutarStrIstatistik(response.acilmamis_tahsilat_toplami) || '0 ₺',
          icon: 'mdi-cash-multiple'
        },
        {
          id: 6,
          isim: 'Derdest Tahsilat Toplamı',
          data: this.$helper.tutarStrIstatistik(response.derdest_tahsilat_tutar_toplam) || '0 ₺',
          icon: 'mdi-cash-multiple'
        },
        {
          id: 7,
          isim: 'Hitam Tahsilat Toplamı',
          data: this.$helper.tutarStrIstatistik(response.hitam_tahsilat_tutar_toplam) || '0 ₺',
          icon: 'mdi-cash-multiple'
        },
        {
          id: 8,
          isim: 'Toplam Tahsilat Oranı',
          data: (response.tahsilat_orani).toFixed(2) + ' %' || '0 %',
          icon: 'mdi-chart-line'
        },
        {
          id: 9,
          isim: 'Açılmamış Tahsilat Oranı',
          data: `${Number(response.acilmamis_tahsilat_orani).toFixed(2)} %` || '0 %',
          icon: 'mdi-chart-line'
        },
        {
          id: 10,
          isim: 'Derdest Tahsilat Oranı',
          data: (response.derdest_tahsilat_orani).toFixed(2) + ' %' || '0 %',
          icon: 'mdi-chart-line'
        },
        {
          id: 11,
          isim: 'Hitam Tahsilat Oranı',
          data: (response.hitam_tahsilat_orani).toFixed(2) + ' %' || '0 %',
          icon: 'mdi-chart-line'
        },

      ]);
      this.loading = true;
    } catch (error) {
      console.error('hata:', error);
      this.loading = true;

    }
  }

  getTooltipText(id: any): any {

    if (this.selectedItemTahsilatOrani === "Genel Toplam") {
      switch (id) {
        case 8:
          return 'Toplam tahsilatın genel toplama bölünmüş halidir';
        case 9:
          return 'Açılmamış tahsilatın açılmamış genel toplama bölünmüş halidir';
        case 10:
          return 'Derdest tahsilatın derdest genel toplama bölünmüş halidir';
        case 11:
          return 'Hitam tahsilatın hitam genel toplama bölünmüş halidir';
        default:
          return '';
      }
    } else if (this.selectedItemTahsilatOrani === "Asıl Alacak") {
      switch (id) {
        case 8:
          return 'Toplam tahsilatın asıl alacağa bölünmüş halidir';
        case 9:
          return 'Açılmamış tahsilatın açılmamış asıl alacağa bölünmüş halidir';
        case 10:
          return 'Derdest tahsilatın derdest asıl alacağa bölünmüş halidir';
        case 11:
          return 'Hitam tahsilatın hitam asıl alacağa bölünmüş halidir';
        default:
          return '';
      }
    } else if (this.selectedItemTahsilatOrani === "Takip Toplamı") {
      switch (id) {
        case 8:
          return 'Toplam tahsilatın takip toplamına bölünmüş halidir';
        case 9:
          return 'Açılmamış tahsilatın açılmamış takip toplamına bölünmüş halidir';
        case 10:
          return 'Derdest tahsilatın derdest takip toplamına bölünmüş halidir';
        case 11:
          return 'Hitam tahsilatın hitam takip toplamına bölünmüş halidir';
        default:
          return '';
      }
    }


  }

  selectItemOran(item:any) {
    this.selectedItemTahsilatOrani = item;
    if (item === "Asıl Alacak") {
      const asilAlacakItem:any = this.items2.find((item:any) => item.id === 0)
      if (asilAlacakItem) {
        asilAlacakItem.dosya_verileri = "0 ₺"
      }
      this.fetchData("asil_alacak", "Asıl Alacak Toplamı")
    } else if (item === "Takip Toplamı") {
      const asilAlacakItem:any = this.items2.find((item:any) => item.id === 0);
      if (asilAlacakItem) {
        asilAlacakItem.dosya_ust_baslik = "Takip Toplamı";
        asilAlacakItem.dosya_verileri = "0 ₺"
      }
      this.fetchData("takip_toplami", "Takip Toplamı")
    } else if (item === "Genel Toplam") {
      const asilAlacakItem:any = this.items2.find((item:any) => item.id === 0);
      if (asilAlacakItem) {
        asilAlacakItem.dosya_ust_baslik = "Genel Toplamı";
        asilAlacakItem.dosya_verileri = "0 ₺"
      }
      this.fetchData("genel_toplam", "Genel Toplam")
    }
  }

  shouldShowTooltip(item:any) {
    const showTooltipIds = [8, 9, 10, 11];
    return showTooltipIds.includes(item.id);
  }

  showGenelToplam(item:any) {
    const showDotIcon = [0]
    return showDotIcon.includes(item.id)
  }

  mapItems(itemsData: any[]) {
    return itemsData.map(itemData => ({
      dosya_ust_baslik: itemData.isim,
      dosya_verileri: itemData.data,
      icon: itemData.icon,
      id: itemData.id
    }));


  }
}
